<template>
  <div>
    <div>
      <img class="comimg" style="width: 100%" src="@/assets/pd/banner1.png">
    </div>
    <div class="res" style="height: 712px">
      <img class="comimg" style="width: 100%" src="@/assets/pd/bgp1.png">
      <div class="abs home-p-com">
        <div class="com-page-t1">您可能正在面临</div>
      </div>
    </div>
    <div class="res" style="height: 1508px">
      <img class="comimg" style="width: 100%" src="@/assets/pd/bgp4.png">
      <div class="abs home-p-com">
        <div class="com-page-t1">企修通智能盘点方案
          <br/>
          让盘点不再是痛苦
        </div>
        <div class="betflex" style="height: 569px;margin-top: 82px">
          <div v-for="(item,index) in threeArr" :key="'t'+index" class="pd-three">
            <div class="pd-three-v">
              <img style="width: 360px;z-index: 100" :src="item.icon">
              <div class="pd-three-tit">{{item.tit}}</div>
              <div class="pd-three-desc"><img style="width: 32px;height: 36px" src="@/assets/pd/点缀.png">{{item.t1}}
              </div>
              <div class="pd-three-desc"><img style="width: 32px;height: 36px" src="@/assets/pd/点缀.png">{{item.t2}}
              </div>
              <div class="pd-three-desc"><img style="width: 32px;height: 36px" src="@/assets/pd/点缀.png">{{item.t3}}
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex;justify-content: center">
          <img style="width: 851px" src="@/assets/pd/line.png">
        </div>
        <div style="display: flex;justify-content: center">
          <img style="width: 1200px" src="@/assets/pd/系统.png">
        </div>
      </div>
    </div>
    <div class="res" style="height: 284px">
      <img class="comimg" style="width: 100%" src="@/assets/pd/bgp3.png">
      <div class="abs home-p-com cflex" style="flex-direction: column;color: white">
        <div style="text-align: center;font-weight: 600;font-size: 40px;line-height: 65px;margin-bottom: 20px">
          企修通软硬件一体化，实现高效、智能盘点
        </div>
        <div class="e-btn" @click="getMore">了解更多</div>
      </div>
    </div>
    <div class="res" style="height: 1049px">
      <img class="comimg" style="width: 100%" src="@/assets/solution/bg6.png">
      <div class="abs home-p-com" style="width: 1277px;">
        <div class="com-page-t1">案例分享
        </div>
        <div class="s-con-v" style="height: 724px">
          <div style="display: flex;height: 342px;margin:60px 39px 2px 38px">
            <div style="flex: 1">
              <div class="s-con-top">广东某职业技术学院</div>
              <div style="display: flex;margin-top: 50px">
                <img style="width: 215px;height: 214px" src="@/assets/pd/book.png">
                <div
                    style="font-size: 18px;font-weight: 400;color: #717477;line-height: 28px;margin: 0 28px;width: 348px;height: 214px;display: flex;flex-direction: column;justify-content: space-between">
                  <div><span style="color: #171717 ">学校概况：</span>
                    医院概况：学校现有四个校区，校园总面积2522亩，教学科研行政用房48万平方米，是一所中国特色水平职业学校和高水平专业群建设单位。
                  </div>
                  <div> 学校现有教职工1600余人，全日制在校生2万多人。该学院设置五个大类的分支机构，其中教学机构，即二级学院15个。</div>

                </div>
              </div>
            </div>
            <img style="width: 575px;height: 324px" src="@/assets/pd/book-img.png">
          </div>
          <span class="s-question">面临的问题：</span>
          <div style="margin: 15px 35px 0 33px;">
            <div v-for="(item,k) in sevenArr" :key="k" class="s-q-bg"
                 :style="{width: k==2?'calc(100% - 12px)':'calc(50% - 12px)'}">
              <img style="float:left;width: 30px;height: 25px;margin: 20px" src="@/assets/solution/ofs-i-chose-dui.png">
              <div style="display: flex;margin: 20px 20px 20px 70px">{{item}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="res" style="height: 701px">
      <img class="comimg" style="width: 100%" src="@/assets/pd/bgp5.png">
      <div class="abs home-p-com">
        <div class="com-page-t1">解决方案</div>
        <div style="font-size: 16px;font-weight: 400;color: #292D33;margin-bottom: 60px;text-align: center">
          企修通固定资产“身份证式”智能盘点系统
        </div>
        <div class="cflex" style="height: 346px;">
          <div v-for="(item,index) in eighthArr" :key="'e'+index" class="ee-bg">
            <div class="show-right">
              <span class="e-right">0{{index+1}}</span>
            </div>
            <img style="width: 76px;height: 77px;margin-left: 18px" :src="item.icon">
            <div class="e-tit">{{item.tit}}</div>
            <div class="e-des">{{item.des}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="res" style="height: 654px">
      <img class="comimg" style="width: 100%" src="@/assets/pd/bgp6.png">
      <div class="abs home-p-com">
        <div class="com-page-t1" style="margin-bottom: 80px">来自客户的评价</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pdSoluting",
  data () {
    return {
      threeArr: [
        {
          icon: require('@/assets/pd/dyj.png'),
          tit: 'RFID标签打印机',
          t1: '导入设备信息',
          t2: '打印速度快',
          t3: '操作简单易上手'
        },
        {
          icon: require('@/assets/pd/biaoqian.png'),
          tit: 'RFID标签',
          t1: '远距离射频',
          t2: '无源RFID',
          t3: '多种样式供您选择'
        },
        {
          icon: require('@/assets/pd/scj.png'),
          tit: '手持盘点机',
          t1: '3-5米手持半径',
          t2: '射频识别扫描',
          t3: '实现高效盘点'
        },
      ],
      sevenArr: [
        '下设分支机构和二级学院众多，设备基数大，且设备的种类众多。',
        '设备盘点方式落后，主要依靠人工进行手动盘点设备，整体的盘点效率低，易出现重复盘点的情况。',
        '在盘点过程中，常常会出现设备遗失或设备外借未还的情况，盘点人员不清楚具体的设备数量，易出现遗漏。',],
      eighthArr: [
        {
          icon: require('@/assets/pd/ofs-i-yunweixitong.png'),
          tit: 'RFID标签',
          des: '每台设备张贴RFID无线射频标签，标签记录设备位置、归属人等信息。'
        },
        {
          icon: require('@/assets/pd/ofs-i-yunweixitong(1).png'),
          tit: 'RFID盘点机',
          des: '搭配RFID标签，无线射频距离可达3-7米，盘点数据显示相应设备状态，实现智能盘点。'
        },
        {
          icon: require('@/assets/pd/ofs-i-yunweixitong(2).png'),
          tit: '系统报表自动生成',
          des: '根据盘点的详情，自动生成数据报表，避免出现盘点重复、盘点遗漏的现象。企修通智能盘点系统可多端口对接，轻松、高效实现设备盘点。 '
        },
      ]
    }
  },
  mounted () {
    this.$parent.gotoTop()
  },
  methods: {
    getMore () {
      this.$router.push('/free')
    }
  }
}
</script>

<style scoped>
  .pd-three {
    width: 388px;
    height: 569px;
    border-radius: 18px;
    border: 1px solid #88B2F5;
    background: linear-gradient(180deg, #E7EEFA 0%, #DDE5FB 100%);
  }

  .pd-three:hover {
    background: linear-gradient(180deg, #9BCEFF 0%, rgba(65, 137, 255, 0.85) 100%);
  }

  .pd-three-v {
    display: flex;
    flex-direction: column;
    border-radius: 18px;
    z-index: 0;
    margin: 14px;
    height: 541px;
    background: linear-gradient(360deg, #F5F9FF 0%, #EEF4FF 100%);
    border-radius: 18px 18px 18px 18px;
    opacity: 1;
    border: 2px solid rgba(255, 255, 255, 0.37);
  }

  .pd-three:hover .pd-three-v {
    background: white;
    border: 1px solid rgba(255, 255, 255, 0.37);
  }

  .pd-three-tit {
    width: 284px;
    height: 61px;
    line-height: 61px;
    background: linear-gradient(180deg, #327DF6 0%, #1F5FFF 100%);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #FFFBFB;
    color: white;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    margin-left: 50px;
    margin-bottom: 40px;
  }

  .pd-three-desc {
    text-align: left;
    margin-left: 97px;
    font-weight: 400;
    color: #35363F;
    line-height: 48px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .s-con-v {
    margin-top: 80px;
    height: 780px;
    background: linear-gradient(360deg, rgba(225, 233, 246, 0.5) 0%, rgba(250, 252, 255, 0.85) 100%);
    border-radius: 18px;
    border: 1px solid #FFFFFF;
  }

  .s-con-top {
    font-size: 36px;
    font-weight: 600;
    color: #1F5FFF;
    line-height: 48px;
  }

  .s-question {
    width: 172px;
    height: 52px;
    line-height: 52px;
    background: rgba(31, 95, 255, 0.11);
    border-radius: 4px 4px 4px 4px;
    font-weight: 600;
    color: #1F5FFF;
    font-size: 22px;
    padding: 11px 20px;
    margin-left: 39px;
  }

  .s-q-bg {
    display: inline-block;
    background: rgba(255, 255, 255, 0.63);
    border-radius: 4px 4px 4px 4px;
    font-size: 20px;
    font-weight: 400;
    color: #171717;
    vertical-align: top;
    width: calc(50% - 12px);
    margin: 5px;
  }

  .s-q-bg:hover {
    color: white;
    background: linear-gradient(180deg, #4189FF 0%, #1F5FFF 100%);
    border: 1px solid #FFFFFF;
  }

  .ee-bg {
    background: linear-gradient(180deg, #FFFFFF 0%, #EBF2FF 100%);
    border-radius: 18px;
    height: 346px;
    border: 2px solid #FFFFFF;
    width: 387px;
    margin: 0 13px;
    display: flex;
    flex-direction: column;
  }

  .ee-bg:hover {
    box-shadow: 10px 10px 10px 1px rgba(31, 95, 255, 0.1);
    border: 1px solid rgba(65, 137, 255, 0.22);
    border-bottom: 4px solid #1F5FFF;
    border-radius: 18px 18px 4px 4px;
  }

  .show-right {
    opacity: 0;
    text-align: right;
  }

  .ee-bg:hover .show-right {
    opacity: 1;
  }

  .e-right {
    width: 70px;
    height: 42px;
    background: linear-gradient(180deg, #4189FF 0%, #1F5FFF 100%);
    border-radius: 0px 18px 0px 20px;
    opacity: 1;
    color: white;
    font-size: 24px;
    font-weight: 400;
    line-height: 42px;
    padding: 4px 18px 4px 22px;
  }

  .e-tit {
    margin: 23px 40px 30px 42px;
    position: relative;
    font-size: 25px;
    font-weight: 600;
    color: #292D33;
    line-height: 29px;
  }

  .e-tit::before {
    position: absolute;
    content: '';
    left: -15px;
    top: 3px;
    width: 5px;
    height: 22px;
    background: #1F5FFF;
  }

  .e-des {
    font-weight: 400;
    color: #606266;
    line-height: 24px;
    font-size: 18px;
    margin: 0 39px 0 27px;
  }
</style>
